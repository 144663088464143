export const _30cmData = {
  name: "Höhe 30 cm",
  rows: [
    [
      {
        title: "Die Ferne so nah",
        year: 2019,
        technique: "Öl/Bristolkarton",
        width: 13,
        height: 29,
        imgSmall: "single-works/30cm/big/DieFerneSoNah.jpg",
        imgDefault: "single-works/30cm/big/DieFerneSoNah.jpg",
        imgFull: "single-works/30cm/big/DieFerneSoNah.jpg",
      },
      {
        title: "Doppelbindung",
        year: 2019,
        technique: "Öl/Bristolkarton",
        width: 15,
        height: 29,
        imgSmall: "single-works/30cm/big/Doppelbindung.jpg",
        imgDefault: "single-works/30cm/big/Doppelbindung.jpg",
        imgFull: "single-works/30cm/big/Doppelbindung.jpg",
      },
      {
        title: "Ein Jeder denkt sich das Seine",
        year: 2015,
        technique: "Öl/Bristolkarton",
        width: 13,
        height: 29,
        imgSmall: "single-works/30cm/big/EinJederDenktSichDasSeine.jpg",
        imgDefault: "single-works/30cm/big/EinJederDenktSichDasSeine.jpg",
        imgFull: "single-works/30cm/big/EinJederDenktSichDasSeine.jpg",
      },
      {
        title: "Frühlingsgefühle",
        year: 2018,
        technique: "Öl/Bristolkarton",
        width: 13,
        height: 29.5,
        imgSmall: "single-works/30cm/big/Frühlingsgefühle.jpg",
        imgDefault: "single-works/30cm/big/Frühlingsgefühle.jpg",
        imgFull: "single-works/30cm/big/Frühlingsgefühle.jpg",
      },
      {
        title: "G+G",
        year: 2021,
        technique: "Öl/Bristolkarton",
        width: 13,
        height: 29.5,
        imgSmall: "single-works/30cm/big/G+G.jpg",
        imgDefault: "single-works/30cm/big/G+G.jpg",
        imgFull: "single-works/30cm/big/G+G.jpg",
      },
      {
        title: "Hyperion",
        year: 2018,
        technique: "Öl/Bristolkarton",
        width: 13,
        height: 29.5,
        imgSmall: "single-works/30cm/big/Hyperion.jpg",
        imgDefault: "single-works/30cm/big/Hyperion.jpg",
        imgFull: "single-works/30cm/big/Hyperion.jpg",
      },
    ],
    [
      {
        title: "Ich liebe Dich",
        year: 2016,
        technique: "Öl/Bristolkarton",
        width: 13,
        height: 29,
        imgSmall: "single-works/30cm/big/IchLiebeDich.jpg",
        imgDefault: "single-works/30cm/big/IchLiebeDich.jpg",
        imgFull: "single-works/30cm/big/IchLiebeDich.jpg",
      },
      {
        title: "In die Ferne schweifen",
        year: 2021,
        technique: "Öl/Bristolkarton",
        width: 8,
        height: 29,
        imgSmall: "single-works/30cm/big/InDieFerneSchweifen.jpg",
        imgDefault: "single-works/30cm/big/InDieFerneSchweifen.jpg",
        imgFull: "single-works/30cm/big/InDieFerneSchweifen.jpg",
      },
      {
        title: "Kleine Fuge",
        year: 2005,
        technique: "Öl/Bristolkarton",
        width: 13,
        height: 29,
        imgSmall: "single-works/30cm/big/KleineFuge.jpg",
        imgDefault: "single-works/30cm/big/KleineFuge.jpg",
        imgFull: "single-works/30cm/big/KleineFuge.jpg",
      },
      {
        title: "Koketterie",
        year: 2021,
        technique: "Öl/Bristolkarton",
        width: 13,
        height: 29,
        imgSmall: "single-works/30cm/big/Koketterie.jpg",
        imgDefault: "single-works/30cm/big/Koketterie.jpg",
        imgFull: "single-works/30cm/big/Koketterie.jpg",
      },
      {
        title: "Sternenguckerin",
        year: 2018,
        technique: "Öl/Bristolkarton",
        width: 8,
        height: 29,
        imgSmall: "single-works/30cm/big/Sternenguckerin.jpg",
        imgDefault: "single-works/30cm/big/Sternenguckerin.jpg",
        imgFull: "single-works/30cm/big/Sternenguckerin.jpg",
      },
      {
        title: "Umgarnt von Rosa",
        year: 2019,
        technique: "Öl/Bristolkarton",
        width: 15,
        height: 29,
        imgSmall: "single-works/30cm/big/UmgarntVonRosa.jpg",
        imgDefault: "single-works/30cm/big/UmgarntVonRosa.jpg",
        imgFull: "single-works/30cm/big/UmgarntVonRosa.jpg",
      },
    ],
  ],
};
