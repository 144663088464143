export const _200cmData = {
  name: "Höhe 200 cm",
  rows: [
    [
      {
        title: "Abschied (Dip.)",
        year: 2015,
        technique: "Öl,Blattgold/Nessel",
        width: 320,
        height: 200,
        imgSmall: "single-works/200cm/big/Abschied.jpg",
        imgDefault: "single-works/200cm/big/Abschied.jpg",
        imgFull: "single-works/200cm/big/Abschied.jpg",
      },
      {
        title: "Danae",
        year: 2020,
        technique: "Öl/Nessel",
        width: 57,
        height: 195,
        imgSmall: "single-works/200cm/big/Danae.jpg",
        imgDefault: "single-works/200cm/big/Danae.jpg",
        imgFull: "single-works/200cm/big/Danae.jpg",
      },
      {
        title: "Die Einsamkeit des Langstreckenläufers",
        year: 2007,
        technique: "Öl/Nessel",
        width: 230,
        height: 200,
        imgSmall: "single-works/200cm/big/DieEinsamkeitDesLangstreckenläufers.jpg",
        imgDefault: "single-works/200cm/big/DieEinsamkeitDesLangstreckenläufers.jpg",
        imgFull: "single-works/200cm/big/DieEinsamkeitDesLangstreckenläufers.jpg",
      },
      {
        title: "L`orange",
        year: 2018,
        technique: "Öl/Nessel",
        width: 57,
        height: 195,
        imgSmall: "single-works/200cm/big/Ellen.jpg",
        imgDefault: "single-works/200cm/big/Ellen.jpg",
        imgFull: "single-works/200cm/big/Ellen.jpg",
      },
      {
        title: "Fuge (Trip.)",
        year: 1997,
        technique: "Öl/Nessel",
        width: 330,
        height: 200,
        imgSmall: "single-works/200cm/big/Fuge.jpg",
        imgDefault: "single-works/200cm/big/Fuge.jpg",
        imgFull: "single-works/200cm/big/Fuge.jpg",
      },
    ],
    [
      {
        title: "Halbzeit",
        year: 2011-16,
        technique: "Öl/Leinwand",
        width: 60,
        height: 200,
        imgSmall: "single-works/200cm/big/Halbzeit.jpg",
        imgDefault: "single-works/200cm/big/Halbzeit.jpg",
        imgFull: "single-works/200cm/big/Halbzeit.jpg",
      },
      {
        title: "Hey you",
        year: 2021,
        technique: "Öl/Nessel",
        width: 105,
        height: 200,
        imgSmall: "single-works/200cm/big/HeyYou.jpg",
        imgDefault: "single-works/200cm/big/HeyYou.jpg",
        imgFull: "single-works/200cm/big/HeyYou.jpg",
      },
      {
        title: "Hin- und hergerissen",
        year: 2017,
        technique: "Öl/Nessel",
        width: 140,
        height: 200,
        imgSmall: "single-works/200cm/big/Hin-UndHergerissen.jpg",
        imgDefault: "single-works/200cm/big/Hin-UndHergerissen.jpg",
        imgFull: "single-works/200cm/big/Hin-UndHergerissen.jpg",
      },
      {
        title: "In´s Licht",
        year: 2020,
        technique: "Öl/Leinwand",
        width: 90,
        height: 200,
        imgSmall: "single-works/200cm/big/InsLicht.jpg",
        imgDefault: "single-works/200cm/big/InsLicht.jpg",
        imgFull: "single-works/200cm/big/InsLicht.jpg",
      },
      {
        title: "It`s me",
        year: 2005,
        technique: "Öl/Nessel",
        width: 90,
        height: 200,
        imgSmall: "single-works/200cm/big/ItsMe.jpg",
        imgDefault: "single-works/200cm/big/ItsMe.jpg",
        imgFull: "single-works/200cm/big/ItsMe.jpg",
      },
    ],
    [
      {
        title: "Mit dem Rücken zur Wand",
        year: 2014,
        technique: "Öl/Nessel",
        width: 170,
        height: 200,
        imgSmall: "single-works/200cm/big/MitDemRückenZurWand.jpg",
        imgDefault: "single-works/200cm/big/MitDemRückenZurWand.jpg",
        imgFull: "single-works/200cm/big/MitDemRückenZurWand.jpg",
      },
      {
        title: "Novalis",
        year: 2019,
        technique: "Öl/Nessel",
        width: 95,
        height: 200,
        imgSmall: "single-works/200cm/big/Novalis.png",
        imgDefault: "single-works/200cm/big/Novalis.png",
        imgFull: "single-works/200cm/big/Novalis.png",
      },
      {
        title: "Roter Mann, man on fire",
        year: 2021,
        technique: "Öl, Ölkreide/Leinwand",
        width: 57,
        height: 200,
        imgSmall: "single-works/200cm/big/RoterMann,ManOnFire.jpg",
        imgDefault: "single-works/200cm/big/RoterMann,ManOnFire.jpg",
        imgFull: "single-works/200cm/big/RoterMann,ManOnFire.jpg",
      },
      {
        title: "Warmer Stoß",
        year: 1995,
        technique: "Öl/Leinwand",
        width: 230,
        height: 200,
        imgSmall: "single-works/200cm/big/WarmerStoß.jpg",
        imgDefault: "single-works/200cm/big/WarmerStoß.jpg",
        imgFull: "single-works/200cm/big/WarmerStoß.jpg",
      },
      {
        title: "In einem anderen Land",
        year: 2021,
        technique: "Öl, Ölkreide/Leinwand",
        width: 75,
        height: 200,
        imgSmall: "single-works/200cm/big/InEinemAnderenLand.jpg",
        imgDefault: "single-works/200cm/big/InEinemAnderenLand.jpg",
        imgFull: "single-works/200cm/big/InEinemAnderenLand.jpg",
      },
    ],
  ],
};
