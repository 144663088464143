<template>
  <div class="mb-48" v-for="category in categories" :key="category.name">
    <Category v-bind:category="category"></Category>
  </div>
</template>

<script>
import Category from "../components/SingleWorks/Category";
import { _200cmData } from "../store/singleWorks/200cm";
import { _30cmData } from "../store/singleWorks/30cm";
// import { _20cmData } from "../store/singleWorks/20cm";
import { _30_36cmData } from "../store/singleWorks/30_36cm";

export default {
  name: "SingleWorks",
  components: {
    Category,
  },
  data: () => {
    return {
      categories: [_200cmData, _30cmData, _30_36cmData],
    };
  },
};
</script>

<style scoped lang="postcss"></style>
