export const _30_36cmData = {
  name: "Höhe 30-36 cm",
  rows: [
    [
      {
        title: "Aus dem Lot",
        year: 2013,
        technique: "Grafit,Öl/Bristolkarton",
        width: 35,
        height: 30,
        imgSmall: "single-works/30-36cm/big/AusDemLot.jpg",
        imgDefault: "single-works/30-36cm/big/AusDemLot.jpg",
        imgFull: "single-works/30-36cm/big/AusDemLot.jpg",
      },
      {
        title: "Dante",
        year: 2021,
        technique: "Papier,Öl,Blattgold/Bristolkarton",
        width: 30,
        height: 36,
        imgSmall: "single-works/30-36cm/big/Dante.jpg",
        imgDefault: "single-works/30-36cm/big/Dante.jpg",
        imgFull: "single-works/30-36cm/big/Dante.jpg",
      },
      {
        title: "Das Paradies der Vögel",
        year: 2014,
        technique: "Papier,Öl,/Bristolkarton",
        width: 30,
        height: 36,
        imgSmall: "single-works/30-36cm/big/DasParadiesDerVögel.jpg",
        imgDefault: "single-works/30-36cm/big/DasParadiesDerVögel.jpg",
        imgFull: "single-works/30-36cm/big/DasParadiesDerVögel.jpg",
      },
      {
        title: "Gezeichnet",
        year: 2014,
        technique: "Öl/Bristolkarton",
        width: 36,
        height: 30,
        imgSmall: "single-works/30-36cm/big/Gezeichnet.jpg",
        imgDefault: "single-works/30-36cm/big/Gezeichnet.jpg",
        imgFull: "single-works/30-36cm/big/Gezeichnet.jpg",
      },
      {
        title: "It´s my home in red",
        year: 2007,
        technique: "Öl/Leinwand",
        width: 90,
        height: 85,
        imgSmall: "single-works/30-36cm/big/ItsMyHomeInRed.jpg",
        imgDefault: "single-works/30-36cm/big/ItsMyHomeInRed.jpg",
        imgFull: "single-works/30-36cm/big/ItsMyHomeInRed.jpg",
      },
      {
        title: "Kistallinerstrand, Istanbul",
        year: 2009,
        technique: "Papier,Öl,/Bristolkarton",
        width: 30,
        height: 36,
        imgSmall: "single-works/30-36cm/big/KristallinerstrandIstanbul.jpg",
        imgDefault: "single-works/30-36cm/big/KristallinerstrandIstanbul.jpg",
        imgFull: "single-works/30-36cm/big/KristallinerstrandIstanbul.jpg",
      },
    ],
    [
      {
        title: "Nehmt mich mit auf die Reise",
        year: 2009,
        technique: "Papier,Öl,/Bristolkarton",
        width: 30,
        height: 36,
        imgSmall: "single-works/30-36cm/big/NehmtMichMitAufDieReise.jpg",
        imgDefault: "single-works/30-36cm/big/NehmtMichMitAufDieReise.jpg",
        imgFull: "single-works/30-36cm/big/NehmtMichMitAufDieReise.jpg",
      },
      {
        title: "Sonnenflecken",
        year: 2012,
        technique: "Öl,Blattgold/Bristolkarton",
        width: 36,
        height: 30,
        imgSmall: "single-works/30-36cm/big/Sonnenflecken.jpg",
        imgDefault: "single-works/30-36cm/big/Sonnenflecken.jpg",
        imgFull: "single-works/30-36cm/big/Sonnenflecken.jpg",
      },
      {
        title: "Spread your wings and fly",
        year: 2009,
        technique: "Öl/Bristolkarton",
        width: 28,
        height: 34,
        imgSmall: "single-works/30-36cm/big/SpreadYourWingsAndFly.jpg",
        imgDefault: "single-works/30-36cm/big/SpreadYourWingsAndFly.jpg",
        imgFull: "single-works/30-36cm/big/SpreadYourWingsAndFly.jpg",
      },
      {
        title: "Stadtgeflüster",
        year: 2017,
        technique: "Öl/Bristolkarton",
        width: 30,
        height: 36,
        imgSmall: "single-works/30-36cm/big/Stadtgeflüster.jpg",
        imgDefault: "single-works/30-36cm/big/Stadtgeflüster.jpg",
        imgFull: "single-works/30-36cm/big/Stadtgeflüster.jpg",
      },
      {
        title: "Stadtkörper",
        year: 2011,
        technique: "Öl,Pastell/Bristolkarton",
        width: 30,
        height: 36,
        imgSmall: "single-works/30-36cm/big/Stadtkörper.jpg",
        imgDefault: "single-works/30-36cm/big/Stadtkörper.jpg",
        imgFull: "single-works/30-36cm/big/Stadtkörper.jpg",
      },
      {
        title: "Trotz des Glückes ein Riß",
        year: 2017,
        technique: "Öl/Bristolkarton",
        width: 36,
        height: 30,
        imgSmall: "single-works/30-36cm/big/TrotzDesGlückesEinRiss.png",
        imgDefault: "single-works/30-36cm/big/TrotzDesGlückesEinRiss.png",
        imgFull: "single-works/30-36cm/big/TrotzDesGlückesEinRiss.png",
      },
    ],
  ],
};
