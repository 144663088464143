<template>
  <h1 class="grey-text sub-header mb-14">{{ category.name }}</h1>
  <div
    class="mb-32"
    v-for="row in rows"
    :key="row"
  >
    <ImgRow v-bind:row="row"></ImgRow>
  </div>
</template>

<script>
import ImgRow from "./ImgRow";
export default {
  name: "Category.vue",
  components: { ImgRow },
  props: {
    category: Object,
  },
  data: function () {
    const rows = this.category.rows.map((row) => {
      return row.map((entry) => {
        return {
          ...entry,
          imgSmall: require(`@/assets/img/${entry.imgSmall}`),
          imgDefault: require(`@/assets/img/${entry.imgDefault}`),
        };
      });
    });
    return {
      rows,
    };
  },
};
</script>

<style scoped></style>
