module.exports = {
  images: "",
  lightboxIndex: 0,
  visible: false,

  enlargeSingle(imgUrl) {
    this.images = imgUrl;
    this.lightboxIndex = 0;
    this.show();
  },
  enlargeMultiple(collection, startIndex) {
    let imgUrls = [];
    collection.forEach((entry) => {
      imgUrls.push(entry.imgDefault);
    });
    this.images = imgUrls;
    this.lightboxIndex = startIndex;
    this.show();
  },
  show() {
    this.visible = true;
  },
  hide() {
    this.visible = false;
  },
};
