<template>
  <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="images"
      :index="lightboxIndex"
      @hide="hide"
  ></vue-easy-lightbox>

  <div class="flex flex-col sm:flex-row justify-between">
    <div class="sm:max-h-60 w-full mb-4 sm:mb-0" v-for="(entry, index) in row" :key="entry.name">
      <div class="h-full w-11/12 mobile-container">
        <img
            :src="entry.imgSmall"
            @click="enlargeMultiple(row, index)"
            alt="{{ entry.title }}"
            class="h-full object-contain cursor-pointer mobile-img"
        />
        <p class="description-text title-text mt-7">{{ entry.title }}</p>
        <p class="description-text">
          {{ entry.year }}, {{ entry.technique }},
          {{ entry.height }} x {{ entry.width }} cm
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  images,
  visible,
  lightboxIndex,
  enlargeMultiple,
  hide,
  show,
} from "../../util/Lightbox.js";

export default {
  name: "ImageRow",
  data: function () {
    return {
      images,
      visible,
      lightboxIndex,
    };
  },
  props: {
    row: Array,
  },
  methods: {
    enlargeMultiple,
    hide,
    show,
  },
};
</script>

<style scoped>
.title-text {
  font-weight: bold !important;
}

.description-text {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
  color: #00000080;
}

@media only screen and (max-width: 600px) {
  .mobile-img {
    max-height: 50vh;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .mobile-container {
    margin-bottom: 4rem;
  }
}
</style>
